import * as React from 'react';
import { Link } from 'gatsby';

interface Props {
  url: string;
  text: string;
  active?: boolean;
}

function HeaderLink(props: Props): JSX.Element {
  const { url, text, active } = props;
  return (
    <Link
      className="rythm-vanish"
      style={{
        display: 'block',
        fontSize: 13,
        lineHeight: 'inherit',
        borderBottom: active ? '1px #222 solid transparent' : 'none',
        boxShadow: active ? undefined : 'none',
        color: '#222',
        textDecoration: 'none',
        outline: 'none',
        margin: '5px 10px',
      }}
      to={url}
    >
      {text}
    </Link>
  );
}

export default HeaderLink;
