import * as React from 'react';
import { Link } from 'gatsby';
import headerPic from '../../content/assets/header.jpeg';
import HeaderLink from './Header/HeaderLink';

interface Props {
  pageId?: string;
}

function Header(props: Props): JSX.Element {
  const { pageId } = props;
  const links = [
    { id: 'home', url: '/', text: 'Home' },
    { id: 'about', url: '/about', text: 'About' },
    { id: 'release-notes', url: '/release-notes', text: 'Release Notes' },
  ];

  return (
    <header
      style={{
        background: `rgba(255,255,255,0.5) url(${headerPic}) no-repeat center center`,
        height: 300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingTop: 60,
      }}
    >
      <div
        className="rythm-twist"
        style={{
          margin: 0,
          padding: '0 40px',
          color: '#fff',
          background: '#222',
          fontSize: 20,
          boxShadow: 'none',
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          Zhiyue · 纸岳
        </Link>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 20,
        }}
      >
        {links.map((link) => (
          <HeaderLink key={link.id} url={link.url} text={link.text} active={pageId === link.id} />
        ))}
      </div>
    </header>
  );
}

export default Header;
