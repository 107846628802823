import { withPrefix } from 'gatsby';
import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { rhythm } from '../utils/typography';

interface Props {
  children: ReactNode;
}
const Layout = ({ children }: Props): JSX.Element => {
  return (
    <>
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: 800,
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer>
      </div>
      <Helmet defer>
        <script src={withPrefix('easter-egg.js')} type="text/javascript" />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/rythm.js/2.2.5/rythm.min.js"></script>
      </Helmet>
    </>
  );
};

export default Layout;
